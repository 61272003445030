<template>
    <ContentWrapper>
        <breadcrumb name="Grow"/>
        <div class="container animated-fast fadeInUp" v-if="grow">
            <b-row>
                <b-col sm="12">
                    <div class="card card-default">
                        <div class="card-header bg-gray-lighter text-bold">Grow - <i class="fad fa-tag mx-2"></i>{{grow.label}}</div>
                    </div>
                </b-col>
            </b-row>
            <b-row>
                <b-col xl="6" lg="6" sm="12" class="mb-3">
                    <div class="card card-gray card-data-table h-100">

                        <div class="card-body">
                            <div class="data-table-body">
                                <b-row>
                                    <b-col cols="8"><i class="fad fa-warehouse mr-2"></i>{{$t('growing.view.grow.location')}}</b-col>
                                    <b-col class="text-right"><span class="badge badge-primary">{{grow.location_name}}</span>
                                    </b-col>
                                </b-row>
                                <b-row>
                                    <b-col cols="8"><i class="fad fa-container-storage mr-2"></i>{{$t('growing.view.grow.room')}}</b-col>
                                    <b-col class="text-right"><span class="badge badge-primary">{{grow.room}}</span>
                                    </b-col>
                                </b-row>
                                <b-row>
                                    <b-col cols="8"><i class="fad fa-cannabis mr-2"></i>{{$t('growing.view.grow.strain')}}</b-col>
                                    <b-col class="text-right"><span
                                            class="badge badge-primary">{{grow.strain_name}}</span></b-col>
                                </b-row>
                            </div>
                        </div>
                    </div>
                </b-col>
                <b-col xl="6" lg="6" sm="12" class="mb-3">
                    <div class="card card-gray card-data-table h-100">

                        <div class="card-body">
                            <div class="data-table-body">
                                <b-row>
                                    <b-col cols="6">{{$t('growing.view.grow.start_date')}}</b-col>
                                    <b-col class="text-right">{{grow.statuses[0].updated_at | moment('utc', 'L') }}
                                    </b-col>
                                </b-row>
                                <b-row>
                                    <b-col cols="6">{{$t('growing.view.grow.plants')}}</b-col>
                                    <b-col class="text-right">{{ grow.plants }}</b-col>
                                </b-row>
                            </div>
                        </div>
                    </div>
                </b-col>
            </b-row>
            <b-row>
                <b-col sm="12" class="mb-3">
                    <div class="card card-gray card-data-table h-100">
                        <div class="card-header">{{$t('growing.view.grow.timeline')}}</div>
                        <div class="card-body">
                            <ul class="timeline-alt mt-4">
                                <template v-for="(status, index) in grow.statuses">
                                    <li class="timeline-separator"
                                        :data-datetime="status.starts_at | moment('utc', 'L')" :key="'li_01_' + index"></li>
                                    <li :key="'li_02_' + index">
                                        <div class="timeline-badge secondary">
                                            <em :class="$t('growing.component.grow.statuses.' + status.name + '.icon')"></em>
                                        </div>
                                        <div class="timeline-card">
                                            <div class="popover left">
                                                <h4 class="popover-header">{{ $t('growing.component.grow.statuses.' +
                                                    status.name + '.title') }}</h4>
                                                <div class="arrow"></div>
                                                <div class="popover-body">
                                                    <p>{{ $t('growing.component.grow.statuses.' + status.name + '.description')
                                                        }}</p>
                                                </div>
                                            </div>
                                        </div>
                                    </li>
                                </template>
                            </ul>
                        </div>
                    </div>
                </b-col>
            </b-row>
        </div>
    </ContentWrapper>
</template>
<style>
    .timeline > li.timeline-separator::before, .timeline-alt > li.timeline-separator::before {
        width: 150px;
        background-color: #999999;
    }
</style>
<script>

    export default {
        components: {},
        data() {
            return {
                grow: null,
                label: "ABCDED"
            }
        },
        mounted() {
            if (this.$route.params.label)
                this.label = this.$route.params.label;


            this.$api.get('growing/grows/' + this.$route.params.label).then(response => {
                this.grow = response.data;
            });
        },
        methods: {}
    }
</script>
